.align-text {
  text-align: center;
}

.blue-text {
  color: #196ECF;
}

.variable-section {
  background-color: #f2f2f2;
}