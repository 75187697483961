.align-text {
  text-align: center;
}

.overflow {
  overflow-wrap: anywhere;
}

.full-height {
  height: 100%;
}

.icon {
  width: 100%;
  color: #196ecf;
}


