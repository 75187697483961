.full-height {
  height: 100%;
}

.icon {
  width: 100%;
  margin-bottom: 8px;
}
.info {
  color: #196ecf;
}

.button-width {
  width: 130px;
}
