.align-text {
  text-align: center;
}

.overflow {
  overflow-wrap: anywhere;
}

.full-height {
  height: 100%;
}


.inline-block {
  display: inline-block
}