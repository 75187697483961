.blue {
    color: #0059a8;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .loading-container {
      height: 50px
  }